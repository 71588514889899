import React from 'react';
import { Link, navigate } from 'gatsby';
// Style import
import '../styles/global.scss';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fingerprint2 from 'fingerprintjs2';
import axios from 'axios';
import { BASE_URL } from '../components/constants';
import DeviceOrientation, { Orientation } from '../components/orientation';

class Main extends React.Component {
  state = {
    uuid: null,
  };

  componentDidMount() {
    // Get the URL parameter from !ClickWorkers!
    const url = new URL(window.location.href);
    console.log(url);
    const uuid = url.searchParams.get('user');
    if (uuid) {
      this.setState({ uuid });
    }

    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        Fingerprint2.get(components => {
          const values = components.map(function(component) {
            return component.value;
          });
          const murmur = Fingerprint2.x64hash128(values.join(''), 31);
          this.checkFingerPrint(murmur);
        });
      });
    } else {
      setTimeout(() => {
        Fingerprint2.get(components => {
          const values = components.map(function(component) {
            return component.value;
          });
          const murmur = Fingerprint2.x64hash128(values.join(''), 31);
          this.checkFingerPrint(murmur);
        });
      }, 500);
    }
  }

  checkFingerPrint = finger => {
    const bodyFormData = new FormData();
    bodyFormData.set('fingerprint', finger);
    axios({
      method: 'post',
      url: `${BASE_URL}/api/fingerprint/`,
      data: bodyFormData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then(function(response) {
        console.log('[Unique Participant]');
      })
      .catch(function(error) {
        navigate('/error', {
          state: {
            message:
              'You have voted in the past or a servers error is happening. If you think this is a server error try again in a couple of minutes.',
          },
        });
      });
  };

  render() {
    // Insert it here in order to avoid ESlint erros ffs
    const { uuid } = this.state;

    return (
      <DeviceOrientation lockOrientation="landscape">
        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <div>
            <Grid container spacing={24}>
              <Grid className="outter">
                <Paper className="intro">
                  <div className="Layout">
                    <Typography component="h1" variant="h4" gutterBottom>
                      Welcome to Loceye's Voting platform.
                    </Typography>
                    <br />

                    <Typography variant="subtitle1" align="left">
                      <strong>Intro</strong>
                      <br />
                      We are conducting a voting research in order to understand
                      the visual impact of Website designs.
                      <br />
                      <br />
                      Your task it to vote which{' '}
                      <span className="positive">
                        Landing Page is looking cleaner and with better design
                      </span>
                      .
                      <br />
                      <br />
                      <strong>Task Description</strong>
                      <br />
                      We are going to display you two landing pages at the same
                      time and you have to vote only one, repeatedly (100
                      times).
                      <br />
                      <br />
                      You are going to have{' '}
                      <span className="attention">~5 seconds</span> to vote each
                      website design, as soon as both of the images are loaded.
                      Don't worry the time is plenty for each design.
                      <br />
                      <br />
                      Between the voting sessions single images will prompt.
                      Those images are goint to{' '}
                      <span className="attention">
                        validate that you are paying attention
                      </span>{' '}
                      in the voting process.
                      <br />
                      You should answer <span className="positive">Yes</span> if
                      you have seen the landing page before or{' '}
                      <span className="attention">No</span> if you have not
                      seeen the landing page before.
                      <br />
                      <br />
                      Take a look the following voting example.
                    </Typography>
                    <br />
                    <img
                      src="https://s3-us-west-2.amazonaws.com/loceye-production/static/example.gif"
                      className="examplegif"
                      alt="demoGif"
                    />
                    <Typography variant="subtitle1" gutterBottom>
                      By clicking continue button below, you’re agreeing to our
                      <a
                        href="https://www.loceye.io/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        {' '}
                        Terms and Conditions
                      </a>
                      .
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => navigate('/voting', { state: this.state })}
                      size="medium"
                      color="primary"
                    >
                      <Link to="/voting" state={this.state}>
                        Continue
                      </Link>
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Orientation>
        {/* Will stay in DOM, but is only visible in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <div>
            <p>
              Please rotate your device and stay in landscape for the rest of
              the Voting!
            </p>
          </div>
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export default Main;
